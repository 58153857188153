import React from 'react';
import { Modal, Button, Form } from 'react-bootstrap';
import '../../Styles/base.css';

const countryCodes = [
    { code: '+91', name: 'India' },
    { code: '+1', name: 'USA' },
    { code: '+44', name: 'UK' },
    { code: '+61', name: 'Australia' },
    { code: '+1', name: 'Canada' },
    { code: '+86', name: 'China' },
    { code: '+33', name: 'France' },
    { code: '+49', name: 'Germany' },
    { code: '+81', name: 'Japan' },
    { code: '+7', name: 'Russia' },
    { code: '+27', name: 'South Africa' },
    { code: '+82', name: 'South Korea' },
    { code: '+34', name: 'Spain' },
    { code: '+46', name: 'Sweden' },
    { code: '+41', name: 'Switzerland' },
    { code: '+90', name: 'Turkey' },
    { code: '+39', name: 'Italy' },
    { code: '+55', name: 'Brazil' },
    { code: '+52', name: 'Mexico' },
    { code: '+31', name: 'Netherlands' },
    { code: '+64', name: 'New Zealand' },
    { code: '+47', name: 'Norway' },
    { code: '+63', name: 'Philippines' },
    { code: '+48', name: 'Poland' },
    { code: '+351', name: 'Portugal' },
    { code: '+966', name: 'Saudi Arabia' },
    { code: '+65', name: 'Singapore' },
    { code: '+66', name: 'Thailand' },
    { code: '+971', name: 'United Arab Emirates' },
  ];

  const ContactModal = ({ show, handleClose }) => {
    return (
      <Modal className="modal-dialog" show={show} onHide={handleClose}>
        <Modal.Header>
          <Modal.Title style={{ fontWeight: 900 }}>Request a callback</Modal.Title>
        </Modal.Header>
        <label style={{ fontWeight: 200, padding: '1rem' }}> Our Next Batch starts in Feburary </label>
        <Modal.Body>
        
          <Form action="https://formsubmit.co/luckyu0020kumar@gmail.com" method="POST" className="contact-form">
            
            <input type="hidden" name="_next" value="https://codebix.in/"/>
            
            <Form.Group controlId="fullName" className="mb-4">
              <Form.Label>Full Name</Form.Label>
              <Form.Control type="text" name="name" required />
            </Form.Group>

            <Form.Group controlId="email" className="mb-4">
              <Form.Label>Email</Form.Label>
              <Form.Control type="email" name="email" required />
            </Form.Group>
  
            <Form.Group controlId="phone" className="mb-4">
              <Form.Label>Phone Number</Form.Label>
              <div className="d-flex flex-column flex-md-row align-items-start">
                <Form.Control as="select" name="countryCode" className="me-md-2 mb-2 mb-md-0" style={{ maxWidth: '10vw', marginRight: '4px' }}>
                  {countryCodes.map((country, idx) => (
                    <option key={idx} value={country.code}>
                      {country.code}
                    </option>
                  ))}
                </Form.Control>
                <Form.Control type="tel" name="phone" />
              </div>
            </Form.Group>
  
            <Form.Group controlId="college" className="mb-4">
              <Form.Label>College or Current Company</Form.Label>
              <Form.Control type="text" name="college" />
            </Form.Group>
    
            <Button className='btn btn-dark' variant="primary" type="submit">
              Send Message
            </Button>
          </Form>
        </Modal.Body>
      </Modal>
    );
  };
  
  
  
export default ContactModal;
