import React, { useState } from "react";
import Navigation from "../Components/Navigation";
import Footer from "./Footer/Footer";
import OfferBanner from "./Utilities/OfferBanner";
import ReviewContainer from "./Reviews/ReviewContainer";
import Header from "./Header/Header";
import { Container, Row, Col } from "react-bootstrap";
import { Instructor } from "../DataSource/Instructor";
import ContactForm from "./Contact/Contact";
import CourseCard from "./Courses/Course";
import { Courses } from "../DataSource/Courses";
import "../Styles/base.css";
import OpportunityCard from "./Utilities/OpportunityCard";
import PlacedCompanies from "./Reviews/PlacedCompanies";
import Whatsapp from "./Utilities/Whatsapp";
import Instructors from "./cards/Instructors";

function Main() {
  const [modalShow, setModalShow] = useState(false);
  return (
    <div>
      <section>
        <Navigation />
      </section>
      <OfferBanner setModalShow={setModalShow} />
      <Header setModalShow={setModalShow} />

      <section id="reviews-section" className="reviews-section">
        <ReviewContainer />
      </section>

      <section>
        <PlacedCompanies />
      </section>

      <section
        id="programs-section"
        style={{
          margin: "2rem",
          borderRadius: "1rem",
        }}
      >
        <Row>
          <Col sm={12} md={12} lg={12}>
            <div style={{ padding: "1rem" }}>
              <h2
                style={{
                  color: "#27374D",
                  textAlign: "center",
                  fontWeight: 700,
                }}
                className="mt-3 mb-2 text-center"
              >
                Our Course Offerings{" "}
              </h2>
              <h4
                style={{
                  color: "#27374D",
                  textAlign: "center",
                  fontWeight: 150,
                }}
              >
                Explore Our Current Course Catalog: Featuring Two Premier
                Programs
              </h4>
            </div>
          </Col>

          <div className="course_cards">
            {Courses !== undefined &&
              Courses.map((courses, index) => {
                return (
                  <CourseCard
                    course={courses}
                    key={index}
                    setModalShow={setModalShow}
                  />
                );
              })}
          </div>
        </Row>
      </section>

      <section className="wrapper-instructor" id="features-instructors">
        <div className="" id="instructor-section">
          <h2
            style={{
              color: "#27374D",
              textAlign: "center",
              fontWeight: 700,
            }}
            className="mt-3 mb-2 text-center"
          >
            Learn Directly from Google and Microsoft Employees
          </h2>
          <p className="text-center px-5 mb-5">
            Insider tips and tricks to cracking the interviews of your dream
            companies straight from the Horse's mouth
          </p>
          <div className="instructors">
            {Instructor !== undefined &&
              Instructor.map((instructor, index) => (
                <Col
                  sm={12}
                  md={6}
                  lg={6}
                  key={index}
                  className="d-flex justify-content-center align-items-center"
                >
                  <Instructors instructor={instructor} />
                </Col>
              ))}
          </div>
        </div>
      </section>

      <section
        id="features-section"
        style={{
          margin: "2rem",
          paddingLeft: "1rem",
          backgroundColor: "",
        }}
        className="container mx-auto"
      >
        <Col>
          <h2
            style={{
              color: "#27374D",
              textAlign: "center",
              fontWeight: 700,
            }}
            className="mt-3 mb-2 text-center"
          >
            Three Step recruitment Strategy
          </h2>
          <div className="opportunity_body">
            <OpportunityCard
              title="Step 1: Live learning"
              bulletPoints={[
                "DSA and System Design",
                "Cover the FAQs at top product company interviews",
                "Become industry-ready with practical insights",
              ]}
              image="learning"
            />
            <OpportunityCard
              title="Step 2: Mock Interviews"
              bulletPoints={[
                "Face interviews directly with top company recruiters",
                "One-on-one interview sessions",
                "Resume review and written feedback",
              ]}
              image="interviews"
            />
            <OpportunityCard
              title="Step 3: Placement Assistance"
              bulletPoints={[
                "HR connects and referrals as per performance review during course duration",
                "First access to hiring opportunities of top product companies.",
              ]}
              image="placement"
            />
          </div>
        </Col>
      </section>

      <section id="features-placements" className="callback_container my-5">
        <Container className="p-1">
          <div className="d-flex flex-column justify-content-center text-center">
            <h2
              style={{
                color: "#27374D",
                textAlign: "center",
                fontWeight: 700,
              }}
              className="mt-3 mb-2"
            >
              Your success story starts here!
            </h2>
            <div>
              Join the ranks of 400+ professionals who've upskilled with Codebix
              and landed their dream jobs.
            </div>

            <a
              className="btn btn-primary w-fit mx-auto my-3"
              // onClick={() => setModalShow(true)}
              href="https://docs.google.com/forms/d/e/1FAIpQLSdm8W4BZbSGxbUvCzhKRTiIDcYXABuYtZSeBFoDvrcKytUa6A/viewform?usp=sf_link"
              target="_blank"
              rel="noreferrer"
            >
              Take free Demo Class!
            </a>

            <ContactForm
              show={modalShow}
              handleClose={() => setModalShow(false)}
            />
          </div>
        </Container>
      </section>

      <section style={{ borderTop: "1px solid whitesmoke" }}>
        <Whatsapp />
        <Footer />
      </section>
    </div>
  );
}

export default Main;
