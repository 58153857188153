import React, { useRef } from "react";
import Slider from "react-slick";
import "./index.css";
import leftArrow from "../../Assets/left-arrow.svg";
import rightArrow from "../../Assets/right-arrow.svg";

export const CustomSlider = (props) => {
  const { children } = props;
  const slider = useRef(null);
  const settings = {
    dots: false,
    infinite: false,
    speed: 500,
    slidesToShow: 3,
    slidesToScroll: 1,
    initialSlide: 0,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
          infinite: true,
          dots: true,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
          initialSlide: 2,
          dots: true,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          dots: true,
        },
      },
    ],
  };

  return (
    <div className="customSlider">
      <button
        className="slickPrevBtn"
        onClick={() => slider?.current?.slickPrev()}
      >
        <img src={leftArrow} alt={"<"} />
      </button>
      <Slider ref={slider} {...settings} className="sliderRef">
        {children}
      </Slider>
      <button
        className="slickNextBtn"
        onClick={() => slider?.current?.slickNext()}
      >
        <img src={rightArrow} alt={">"} />
      </button>
    </div>
  );
};
